
  import userController from '/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/src/components/ThankYouWidget/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://cd2c8e8d2a11492b9ae615ed62ef6072@sentry.wixpress.com/3606',
    id: 'ddac5f9ae34511ec9fff0242ac12001c',
    projectName: 'wix-reviews',
    teamName: 'forum',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["todo-viewer-apps-95a5fce-e370-4402-9ce4-96956acc747d"],"centralized":true};

  var translationsConfig = {"enabled":true,"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = {"enableUniversalEvents":true};

  var defaultTranslations = {"thank-you.title":"Thank You!","thank-you.body":"Your review is now live and could help other shoppers make the right decision!","thank-you.cta":"See your review","review-exists.title":"Thank You!","review-exists.body":"Seems like you’ve already left a review for this product!","review-exists.cta":"See your review","entity-not-found.title":"Oops!","entity-not-found.cta":"Continue","entity-not-found.body":"This product was deleted!"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix Reviews",
    componentName: "ThankYouWidget",
    appDefinitionId: "a95a5fce-e370-4402-9ce4-96956acc747d",
    componentId: "25fe3f83-0036-4471-9134-817d5591d737",
    projectName: "wix-reviews",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "components/ThankYouWidget/locales/widget"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/7316bfa601ecc640/reviews-web/wix-reviews/src/components/ThankYouWidget/controller.ts",
  };

  export const controller = _controller
  export default controller;
